<template>
  <page-banner v-if="gameCode" />
  <section v-if="gameCode" class="inner-page">
    <div class="container">
      <div class="row">
        <sidebar @handle-click-sidebar="handleClickSidebar" />
        <div class="list">
          <div class="game-content">
            <div class="phone">
              <img :src="require(`@/assets/images/phone-dark.png`)" />
              <div class="game-img" :class="gameType">
                <img
                  v-if="gameCode"
                  :src="require(`@/assets/images/gameIcon/${gameCode}.png`)"
                />
                <h4>{{ $t(`common.gameName.${gameCode}`) }}</h4>
              </div>
            </div>
            <div class="details">
              <div class="heading">
                <img
                  v-if="gameCode"
                  :src="require(`@/assets/images/gameIcon/${gameCode}.png`)"
                />
                <h4>{{ $t(`common.gameName.${gameCode}`) }}</h4>
              </div>
              <div class="directions">
                {{ $t("common.menuList.gameTypes") }}
                <span class="content">
                  {{ $t(`common.gameType.${gameType}`) }}</span
                >
                <ul>
                  <li>
                    {{ $t("common.gameInfo.awardPeriod") }}
                    <span class="content">{{
                      $t(`common.awardPeriod.${gameCode}`)
                    }}</span>
                  </li>
                  <li>
                    {{ $t("common.gameInfo.betType") }}
                    <span class="content">{{ game.betTypes }}</span>
                  </li>
                  <li v-if="game.bundleTypes && game.bundleTypes.length > 0">
                    {{ $t("common.gameInfo.bundleType") }}
                    <span class="content">
                      <span
                        v-for="(type, index) in game.bundleTypes"
                        :key="type"
                      >
                        {{ $t(`common.bundleType.${type}`)
                        }}<i v-if="index !== game.bundleTypes.length - 1">, </i>
                      </span>
                    </span>
                  </li>
                  <li>
                    {{ $t("common.gameInfo.maxPayout") }}
                    <span class="content">{{
                      $filters.number(game.maxPayout)
                    }}</span>
                  </li>
                  <li>
                    {{ $t("common.gameInfo.deviceSupport") }}
                    <span class="content">
                      <span v-for="(item, index) in deviceList" :key="item">
                        {{ item
                        }}<i v-if="index !== deviceList.length - 1">, </i>
                      </span></span
                    >
                  </li>
                  <li>
                    {{ $t("common.gameInfo.languageSupport") }}
                    <span class="content">
                      <span
                        v-for="(item, index) in brandConfig.gameLanguageList"
                        :key="item"
                      >
                        {{ upperCase(item)
                        }}<i
                          v-if="
                            index !== brandConfig.gameLanguageList.length - 1
                          "
                          >,
                        </i>
                      </span>
                    </span>
                  </li>
                </ul>
                <div>
                  {{ $t("common.gameInfo.rule") }}
                  <span class="content">{{
                    $t(`common.gameRules.${gameCode}`)
                  }}</span>
                </div>
                <button
                  v-if="game.playGame"
                  class="play-game"
                  @click="handlePlayGame(game)"
                >
                  {{ $t("common.playGame") }}
                </button>
                <div
                  class="backto"
                  @click="goPage({ page: 'List', params: { gameType } })"
                >
                  <i class="fas fa-chevron-left"></i>
                  {{ $t("common.return") }}
                </div>
              </div>
            </div>
          </div>
          <div class="similar-games">
            <h4>{{ $t("common.similarGame") }}</h4>
            <div class="game-link">
              <button
                v-for="item in similarGameList"
                :key="item.gameCode"
                @click="handleChangeGame(item)"
              >
                <img
                  :src="
                    require(`@/assets/images/gameIcon/${item.gameCode}.png`)
                  "
                />
                <span>{{ $t(`common.gameName.${item.gameCode}`) }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent, ref, reactive, provide, inject, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  find, upperCase, forEach, findIndex,
} from 'lodash';
import PageBanner from '@/components/PageBanner.vue';
import Sidebar from '@/components/Sidebar.vue';
import games from '@/config/games.json';

export default defineComponent({
  components: {
    PageBanner,
    Sidebar,
  },
  setup() {
    const $route = useRoute();
    const brandConfig = inject('brandConfig');
    const goPage = inject('goPage');
    const handlePlayGame = inject('handlePlayGame');
    const game = reactive({});
    const gameCode = ref(null);
    const gameType = ref(null);
    const deviceList = ['IOS', 'Android', 'Windows', 'MacOS'];

    provide('gameType', gameType);
    const handleClickSidebar = (item) => {
      goPage({ page: 'List', params: { gameType: item } });
    };
    const handleChangeGame = (item) => {
      goPage({ page: 'Detail', params: { gameName: item.gameName } });
      gameCode.value = item.gameCode;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };
    onMounted(() => {
      if ($route.params.gameName) {
        const match = find(brandConfig.gameList, (item) => item.gameName === $route.params.gameName);
        if (match) {
          gameCode.value = match.gameCode;
          window.scrollTo(0, 0);
        } else {
          goPage({ page: 'Home' });
        }
      } else {
        goPage({ page: 'Home' });
      }
    });
    return {
      upperCase,
      brandConfig,
      goPage,
      handlePlayGame,
      game,
      gameCode,
      gameType,
      deviceList,
      handleClickSidebar,
      handleChangeGame,
    };
  },
  data() {
    return {
      similarGameList: [],
    };
  },
  watch: {
    gameCode(val) {
      if (val) {
        this.game = find(games.gameList, (item) => item.gameCode === val);
        // eslint-disable-next-line prefer-destructuring
        this.gameType = this.game.gameTypes[0];
        this.getSimilarGameList();
      }
    },
  },
  methods: {
    getSimilarGameList() {
      const gameList = {};
      forEach(this.brandConfig.gameList, (game) => {
        const match = find(games.gameList, (item) => item.gameCode === game.gameCode);
        if (match) {
          forEach(match.gameTypes, (item) => {
            if (gameList[item]) {
              gameList[item].push(game);
            } else {
              gameList[item] = [game];
            }
          });
        }
      });
      if (gameList[this.gameType].length > 1) {
        const n = findIndex(gameList[this.gameType], (item) => item.gameCode === this.gameCode);
        gameList[this.gameType].splice(n, 1);
        this.similarGameList = gameList[this.gameType].slice(0, 5);
      } else {
        console.log(gameList);
        this.similarGameList = gameList.hot.slice(0, 5);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.list {
  @media (min-width: 1200px) {
    width: 75%;
  }
  .play-game {
    margin-top: 30px;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
    background: linear-gradient(to bottom, #cc00ff, #580f5f);
    box-shadow: inset 0 1px 3px rgba(255, 255, 255, 0.3),
      inset 0 1px 0 rgba(255, 255, 255, 0.1),
      inset 0 -1px 1px 1px rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 10px 30px;
    color: #fff;
    line-height: 1.5;
    border-radius: 10px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.1)
      );
    }
    &:hover {
      filter: grayscale(30%);
    }
  }
  .game-content {
    display: flex;
    align-items: flex-start;
    @media (max-width: 991px) {
      padding-top: 1rem;
    }
  }
  .phone {
    width: 300px;
    position: relative;
    z-index: 2;
    @media (max-width: 1199px) and (min-width: 768px) {
      width: 200px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .game-img {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 70%;
    text-align: center;
    padding-top: 40px;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    @media (max-width: 1199px) and (min-width: 768px) {
      top: 30px;
      padding-top: 20px;
    }
    &.lottery {
      background-image: url("../assets/images/style-bg/lottery.jpg");
    }
    &.stock {
      background-image: url("../assets/images/style-bg/stock.jpg");
    }
    &.liveStream {
      background-image: url("../assets/images/style-bg/liveStream.jpg");
    }
    &.rng {
      background-image: url("../assets/images/style-bg/rng.jpg");
    }
    &.ball {
      background-image: url("../assets/images/style-bg/ball.jpg");
    }
    &.graph {
      background-image: url("../assets/images/style-bg/graph.jpg");
    }
    img {
      width: 60%;
      -webkit-box-reflect: below 0 -webkit-linear-gradient(rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.35));
      margin-bottom: 30px;
    }
    h4 {
      color: #fff;
      font-weight: bold;
      text-shadow: 1px 2px 3px #000;
      margin: 0;
      font-size: 1.5rem;
      @media (max-width: 1199px) and (min-width: 768px) {
        font-size: 1em;
      }
    }
  }
  .details {
    width: calc(100% - 200px);
    margin-left: -100px;
    /* width 200 + margin 100 = phone width */
    position: relative;
    z-index: 1; /* 此層級可以讓此區塊做margin負值可以在phone後面 */
    @media (max-width: 1199px) and (min-width: 768px) {
      width: calc(100% - 100px);
      margin-left: -100px;
      /* width 200 + margin 100 = phone width */
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
    .heading {
      padding: 30px 30px 30px 120px;
      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        padding: 30px;
      }
      @media (max-width: 480px) {
        padding: 20px;
      }
      img {
        display: none;
        @media (max-width: 767px) {
          display: inline-block;
          width: 50px;
          margin-right: 10px;
        }
      }
      h4 {
        margin: 0;
        color: #895983;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }
    .directions {
      padding: 30px 30px 60px 120px;
      border-radius: 10px;
      background: #f7f7f7;
      overflow: hidden;
      line-height: 1.5;
      @media (max-width: 767px) {
        padding: 30px 30px 60px;
      }
      @media (max-width: 480px) {
        padding: 20px 20px 50px;
      }
      .content {
        display: block;
        color: #000;
        font-size: 20px;
      }
      ul {
        margin: 10px 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          padding: 10px 0;
          @media (max-width: 480px) {
            width: 100%;
          }
        }
      }
    }
    .backto {
      position: absolute;
      z-index: 1;
      right: 20px;
      bottom: 0;
      display: block;
      padding: 10px 20px;
      border-radius: 5px 5px 0 0;
      background: #000;
      color: #fff;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .similar-games {
    border-top: 1px solid #dedede;
    margin-top: 100px;
    padding-top: 50px;
    h4 {
      color: #000;
      font-size: 1.5rem;
    }
    .game-link {
      display: flex;
      align-items: flex-start;
      padding: 10px;
      color: #666;
      text-align: center;
      flex-wrap: nowrap;
      @media (max-width: 480px) {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        margin-bottom: 10px;
      }
      button {
        transition: 0.3s;
        @media (min-width: 480px) {
          width: 20%;
          box-sizing: border-box;
          padding: 20px;
        }
        @media (max-width: 480px) {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 10px;
          margin-bottom: 10px;
        }
        &:hover {
          color: #000;
          transform: translateY(-5px);
          @media (max-width: 480px) {
            color: inherit;
            transform: translateY(0);
          }
        }
        span {
          @media (min-width: 480px) {
            display: block;
          }
        }
      }
      img {
        width: 100px;
        margin-bottom: 20px;
        @media (max-width: 600px) {
          width: 50px;
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
